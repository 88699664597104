<template>
<div>
    <v-alert 
    v-if="errors.length"
    class="text-body-2"
    color="error"
    type="error"
    tile>
      <div v-for="error in errors" v-bind:key="error.text">
        {{error.text}}
        {{error.advice}}
      </div>
    </v-alert>
</div>
</template>

<script>
export default {
  name: 'Errors',

  props: {
    errors: { //ist ein Objekt mit den Attributen text, advice und type. Advice wird ggf erst in created gesetzt
      type: Array,
      default: () => []
    },
  },

  watch: {
    errors: {
      immediate: true,
      handler() {
        /* Add advice texts for standart errors */
        for(let i=0; i<this.errors.length; i++){
          let error = this.errors[i];

          /* Couldn't get a document from firestore */
          let hasAdviceText = Object.prototype.hasOwnProperty.call(error, 'advice');
          if(error.type == 'firestore' && !hasAdviceText){
            error.advice = 'Überprüfe, ob du eine Internetverbindung hast und der Link korrekt ist. Sollte das Problem andauern bekommst du unter https://help.alearn.de Hilfe.'
          } else if(error.type == 'help' && !hasAdviceText){
            error.advice = 'Bitte wende dich unter https://help.alearn.de an das ALearn Team.'
          }
        }
      }
    }
  },

}
</script>

<style>

</style>